<template>
  <v-dialog v-model="input" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Import Templates</v-card-title>
      <v-container>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              label="Tenant"
              :value="getStickyTenant"
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-select
              label="Application"
              v-model="currentTenantApp"
              :items="tenantApps"
              itemValue="code"
              itemText="name"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <BaseSelectChip
              label="Selected Templates"
              :value="selectedTemplates"
              :available="filteredTemplates"
              itemValue="name"
              itemText="name"
              hint="Select pre-defined templates to import"
              @input="updatedSelected"
             />
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" flat @click="clicked(false)">Cancel</v-btn>
        <v-btn color="accent" flat @click="clicked(true)">Import</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import templateList from '@/components/config/tenantAppTemplates/templateList';
import appTemplateService from '@/services/ApplicationTemplateService';
import tenantService from '@/services/TenantService';
import tenantTemplateService from '@/services/TenantTemplateService';
import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';

export default {
  name: 'TemplateImportModal',
  components: {
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
  },
  data: () => ({
    templateList,
    selectedTemplates: [],
    currentTenantApp: '',
  }),
  props: {
    open: Boolean,
  },
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getSelectedTemplateApp']),
    ...mapGetters('tenantBiometrics', {
      tenantBiometrics: 'getAll',
    }),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
    input: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    filteredTemplates() {
      const availableCaptureTypes = this.tenantBiometrics.map(biometric => biometric.captureType);
      // Loop through each defined template and make sure that the tenant has
      // a biometric of that capture type enabled.
      const filteredTemplates = this.templateList.filter(template => template.includedCaptureTypes
        .every(type => (availableCaptureTypes.indexOf(type) !== -1)));

      // Add "Select All" option
      filteredTemplates.unshift({
        name: 'SELECT ALL',
        code: 'SELECT ALL',
      });

      return filteredTemplates;
    },
  },
  methods: {
    ...mapActions('app', ['setSelectedTemplateApp']),
    ...mapActions('applicationTemplates', {
      saveTemplate: 'save',
      fetchAppTemplates: 'fetchAllBy',
    }),
    ...mapActions('tenantTemplateBiometrics', {
      associateTenantTemplateBiometric: 'associateTenantTemplateBiometric',
    }),
    ...mapActions('tenantBiometrics', {
      fetchTenantBiometrics: 'fetchBiometrics',
    }),
    async clicked(accepted) {
      this.input = false;
      const vm = this;
      if (accepted) {
        // Start import process.
        // eslint-disable-next-line no-restricted-syntax
        for (const template of this.selectedTemplates) {
          // eslint-disable-next-line no-await-in-loop
          await this.saveTemplate({
            service: appTemplateService,
            item: {
              template,
              tenantCode: vm.getStickyTenant,
              appCode: vm.currentTenantApp,
            },
          });

          // eslint-disable-next-line no-await-in-loop
          await this.saveTemplateBiometrics(template);
        }
      }
      // Clear out selected values.
      this.selectedTemplates = [];
      this.$emit('importModalClicked', accepted);
    },
    async saveTemplateBiometrics(template) {
      const vm = this;
      // Save template biometrics
      await template.includedCaptureTypes.forEach((type, index) => {
        // We have to get the algorithm details for the capture type that this
        // tenant has.
        const algMatch = this.tenantBiometrics
          .filter(biometric => biometric.captureType === type)[0];

        const body = {
          sequence: index,
          captureType: type,
          algCode: algMatch.code,
          code: algMatch.code,
        };

        this.associateTenantTemplateBiometric({
          service: tenantTemplateService,
          tenantCode: vm.getStickyTenant,
          appCode: vm.currentTenantApp,
          templateCode: template.name,
          captureType: type,
          biometric: body,
        });
      });
    },
    updatedSelected(algs) {
      // Check if "Select All" item was selected.
      const selectAll = algs.filter(alg => alg.name === 'SELECT ALL');
      if (selectAll.length === 1) {
        this.selectedTemplates = this.filteredTemplates.filter(alg => alg.name !== 'SELECT ALL');
      } else {
        this.selectedTemplates = algs.filter(alg => alg.name !== 'SELECT ALL');
      }
    },
  },
  mounted() {
    this.fetchTenantBiometrics({
      service: tenantService,
      key: 'code',
      tenantCode: this.getStickyTenant,
      Transform: TenantBiometricToPlain,
    });
    this.currentTenantApp = this.getSelectedTemplateApp;
  },
};
</script>
