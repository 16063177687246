const defaultValues = {
  type: 'native',
  headerText: 'An alert has been issued.',
  bodyText: 'Please verify your identity.',
  body: '',
  alertBackgroundColor: '#4A4A4A',
  alertTextColor: '#4A4A4A',
  alertTextboxColor: '#FFFFFF',
  logoUrl: '',
  logoDisplayType: 'application',
  backgroundImageUrl: '',
};

const templateDefinitions = [
  {
    name: 'CHOICE_ALL',
    algorithmOperation: 'choice',
    includedCaptureTypes: ['face', 'passphrase', 'pin', 'palm', 'device'],
  },
  {
    name: 'CHOICE_FACEDEVICE',
    algorithmOperation: 'choice',
    includedCaptureTypes: ['face', 'device'],
  },
  {
    name: 'CHOICE_FACEPINPALM',
    algorithmOperation: 'choice',
    includedCaptureTypes: ['face', 'pin', 'palm'],
  },
  {
    name: 'CHOICE_PINPALMVOICEDEVICE',
    algorithmOperation: 'choice',
    includedCaptureTypes: ['pin', 'palm', 'passphrase', 'device'],
  },
  {
    name: 'DEVICE',
    algorithmOperation: 'single',
    includedCaptureTypes: ['device'],
  },
  {
    name: 'FACE',
    algorithmOperation: 'single',
    includedCaptureTypes: ['face'],
  },
  {
    name: 'FACE_VOICE',
    algorithmOperation: 'nested',
    includedCaptureTypes: ['face', 'passphrase'],
  },
  {
    name: 'PIN',
    algorithmOperation: 'single',
    includedCaptureTypes: ['pin'],
  },
  {
    name: 'PIN_DEVICE',
    algorithmOperation: 'nested',
    includedCaptureTypes: ['pin', 'device'],
  },
  {
    name: 'PIN_PALM',
    algorithmOperation: 'nested',
    includedCaptureTypes: ['pin', 'palm'],
  },
  {
    name: 'PALM',
    algorithmOperation: 'single',
    includedCaptureTypes: ['palm'],
  },
  {
    name: 'VOICE',
    algorithmOperation: 'single',
    includedCaptureTypes: ['passphrase'],
  },
  {
    name: 'VOICE_DEVICE',
    algorithmOperation: 'nested',
    includedCaptureTypes: ['passphrase', 'device'],
  },
  {
    name: 'YESNO',
    algorithmOperation: 'single',
    includedCaptureTypes: ['yesno'],
  },
];

const templateList = templateDefinitions.map((template) => {
  const combined = Object.assign(template, defaultValues);
  // We have to add a code because the chip in BaseSelectChip requires it.
  combined.code = combined.name;
  return combined;
});

export default templateList;
